$dark: #000000;
$black: #232323;

body {
  margin: 0;
  font-family: "Fira Sans Extra Condensed", sans-serif;
  font-size: 28px;
  color: $black;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

p {
  color: black;
  font-family: "Fira Sans Condensed", sans-serif;
  font-size: 0.75em;
  line-height: 34px;
}

h2 {
  font-size: 1em;

  @media (min-width: 1110px) {
    font-size: 1.5em;
  }
}

li {
  list-style-type: none;
}

a {
  color: $black;
  text-decoration: none;
}

a:hover:not(.snowflake) {
  border-bottom: 5px solid $dark;
}

.stroke {
  border: 0.08em solid $dark ;
  width: 0.84em;
  height: 0.84em;
  border-radius: 50%;
}

.circle {
  position: relative;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  background-color: white;
  margin: 0 auto;
  font-size: 240px;
  transform: rotate(90deg);
  top: -140px;
}

.mask {
  position: absolute;
  clip: rect(0em, 1em, 1em, 0.5em);
}

.line, .home-line {
  display: none;

  @media (min-width: 1110px) {
    display: block;
    border-top: 20px solid $dark;
    position: relative;
    top: 70px;
  }
}

.line {
  @media (min-width: 1110px) {
    background-color: #e2e8f9;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='52' height='52' viewBox='0 0 52 52'%3E%3Cpath fill='%23407480' fill-opacity='0.11' d='M0 17.83V0h17.83a3 3 0 0 1-5.66 2H5.9A5 5 0 0 1 2 5.9v6.27a3 3 0 0 1-2 5.66zm0 18.34a3 3 0 0 1 2 5.66v6.27A5 5 0 0 1 5.9 52h6.27a3 3 0 0 1 5.66 0H0V36.17zM36.17 52a3 3 0 0 1 5.66 0h6.27a5 5 0 0 1 3.9-3.9v-6.27a3 3 0 0 1 0-5.66V52H36.17zM0 31.93v-9.78a5 5 0 0 1 3.8.72l4.43-4.43a3 3 0 1 1 1.42 1.41L5.2 24.28a5 5 0 0 1 0 5.52l4.44 4.43a3 3 0 1 1-1.42 1.42L3.8 31.2a5 5 0 0 1-3.8.72zm52-14.1a3 3 0 0 1 0-5.66V5.9A5 5 0 0 1 48.1 2h-6.27a3 3 0 0 1-5.66-2H52v17.83zm0 14.1a4.97 4.97 0 0 1-1.72-.72l-4.43 4.44a3 3 0 1 1-1.41-1.42l4.43-4.43a5 5 0 0 1 0-5.52l-4.43-4.43a3 3 0 1 1 1.41-1.41l4.43 4.43c.53-.35 1.12-.6 1.72-.72v9.78zM22.15 0h9.78a5 5 0 0 1-.72 3.8l4.44 4.43a3 3 0 1 1-1.42 1.42L29.8 5.2a5 5 0 0 1-5.52 0l-4.43 4.44a3 3 0 1 1-1.41-1.42l4.43-4.43a5 5 0 0 1-.72-3.8zm0 52c.13-.6.37-1.19.72-1.72l-4.43-4.43a3 3 0 1 1 1.41-1.41l4.43 4.43a5 5 0 0 1 5.52 0l4.43-4.43a3 3 0 1 1 1.42 1.41l-4.44 4.43c.36.53.6 1.12.72 1.72h-9.78zm9.75-24a5 5 0 0 1-3.9 3.9v6.27a3 3 0 1 1-2 0V31.9a5 5 0 0 1-3.9-3.9h-6.27a3 3 0 1 1 0-2h6.27a5 5 0 0 1 3.9-3.9v-6.27a3 3 0 1 1 2 0v6.27a5 5 0 0 1 3.9 3.9h6.27a3 3 0 1 1 0 2H31.9z'%3E%3C/path%3E%3C/svg%3E");
  }
}

.line:before {
  position:absolute;
  bottom:0;
  left:0;
  width:100%;
  height:50%;
  content:"";
  background-color:white;
}

.nav {
  max-width: 100%;
  margin: 0 auto;
  padding: 0;
  position: relative;
  display: flex;
  justify-content: space-between;

  @media (min-width: 1110px) {
    max-width: 1100px;
    top: -250px;
  }
}

.nav-item-container {
  display: inline-block;
  font-weight: 800;
  width: 450px;
  text-align: center;
  margin-top: 10px;

  @media (min-width: 1110px) {
    margin-top: 15px;
  }
}

.nav-item {
  display: inline;
  font-size: 0.6em;
  padding-right: 10px;

  @media (min-width: 1110px) {
    display: inline-block;
    padding: 0 30px;
    font-size: 1em;
  }
}

.not-mobile {
  display: none;

  @media (min-width: 1110px) {
    display:  inline-block;
  }
}

.nav-item__flake-space {
  width: 100px;
  z-index: -10;
}

.snowflake-container {
  position: relative;
  top: 10px;
}

ul {
  padding-inline-start: 0 !important;
}

.snowflake {
  content: url(/images/FLAKE.jpg);
  margin: 0 auto;
  width: 10em;
  mix-blend-mode: multiply;
  display: inherit;

  @media (min-width: 1110px) {
    width: 4em;
  }
}

.snowflake:hover {
  filter: brightness(1.5);
}

.banner {
  display: none;

  @media (min-width: 1110px) {
    display: block;
    background: url(/images/alice.jpg);
    background-repeat: no-repeat;
    background-size: 100%;
    min-height: 500px;
    position: absolute;
    width: 100%;
    background-position: 0 40%;
    top: 70px;
    transition: background 0.4s ease;
  }
}

.banner:hover {
  background: url(/images/alice-hover.jpg);
  background-repeat: no-repeat;
  background-size: 100%;
  min-height: 500px;
  position: absolute;
  width: 100%;
  background-position: 0 40%;
  top: 70px;
  transition: background 0.4s ease;
}


.container h1 {
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  color: #ffffff;
  text-shadow: 1px 1px 1px #642bf5;
  padding: 10px 0;
  margin: 0;
  border-bottom: 12px solid $dark;
  text-align: center;
  font-size: 1em;
  background-color: $dark;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='52' height='52' viewBox='0 0 52 52'%3E%3Cpath fill='%23407480' fill-opacity='0.11' d='M0 17.83V0h17.83a3 3 0 0 1-5.66 2H5.9A5 5 0 0 1 2 5.9v6.27a3 3 0 0 1-2 5.66zm0 18.34a3 3 0 0 1 2 5.66v6.27A5 5 0 0 1 5.9 52h6.27a3 3 0 0 1 5.66 0H0V36.17zM36.17 52a3 3 0 0 1 5.66 0h6.27a5 5 0 0 1 3.9-3.9v-6.27a3 3 0 0 1 0-5.66V52H36.17zM0 31.93v-9.78a5 5 0 0 1 3.8.72l4.43-4.43a3 3 0 1 1 1.42 1.41L5.2 24.28a5 5 0 0 1 0 5.52l4.44 4.43a3 3 0 1 1-1.42 1.42L3.8 31.2a5 5 0 0 1-3.8.72zm52-14.1a3 3 0 0 1 0-5.66V5.9A5 5 0 0 1 48.1 2h-6.27a3 3 0 0 1-5.66-2H52v17.83zm0 14.1a4.97 4.97 0 0 1-1.72-.72l-4.43 4.44a3 3 0 1 1-1.41-1.42l4.43-4.43a5 5 0 0 1 0-5.52l-4.43-4.43a3 3 0 1 1 1.41-1.41l4.43 4.43c.53-.35 1.12-.6 1.72-.72v9.78zM22.15 0h9.78a5 5 0 0 1-.72 3.8l4.44 4.43a3 3 0 1 1-1.42 1.42L29.8 5.2a5 5 0 0 1-5.52 0l-4.43 4.44a3 3 0 1 1-1.41-1.42l4.43-4.43a5 5 0 0 1-.72-3.8zm0 52c.13-.6.37-1.19.72-1.72l-4.43-4.43a3 3 0 1 1 1.41-1.41l4.43 4.43a5 5 0 0 1 5.52 0l4.43-4.43a3 3 0 1 1 1.42 1.41l-4.44 4.43c.36.53.6 1.12.72 1.72h-9.78zm9.75-24a5 5 0 0 1-3.9 3.9v6.27a3 3 0 1 1-2 0V31.9a5 5 0 0 1-3.9-3.9h-6.27a3 3 0 1 1 0-2h6.27a5 5 0 0 1 3.9-3.9v-6.27a3 3 0 1 1 2 0v6.27a5 5 0 0 1 3.9 3.9h6.27a3 3 0 1 1 0 2H31.9z'%3E%3C/path%3E%3C/svg%3E");
  letter-spacing: 5px;

  @media (min-width: 1110px) {
    font-size: 2em;
    letter-spacing: 10px;
  }
}

.page-container h1 {
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  background-color: #e2e8f9;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='52' height='52' viewBox='0 0 52 52'%3E%3Cpath fill='%23407480' fill-opacity='0.11' d='M0 17.83V0h17.83a3 3 0 0 1-5.66 2H5.9A5 5 0 0 1 2 5.9v6.27a3 3 0 0 1-2 5.66zm0 18.34a3 3 0 0 1 2 5.66v6.27A5 5 0 0 1 5.9 52h6.27a3 3 0 0 1 5.66 0H0V36.17zM36.17 52a3 3 0 0 1 5.66 0h6.27a5 5 0 0 1 3.9-3.9v-6.27a3 3 0 0 1 0-5.66V52H36.17zM0 31.93v-9.78a5 5 0 0 1 3.8.72l4.43-4.43a3 3 0 1 1 1.42 1.41L5.2 24.28a5 5 0 0 1 0 5.52l4.44 4.43a3 3 0 1 1-1.42 1.42L3.8 31.2a5 5 0 0 1-3.8.72zm52-14.1a3 3 0 0 1 0-5.66V5.9A5 5 0 0 1 48.1 2h-6.27a3 3 0 0 1-5.66-2H52v17.83zm0 14.1a4.97 4.97 0 0 1-1.72-.72l-4.43 4.44a3 3 0 1 1-1.41-1.42l4.43-4.43a5 5 0 0 1 0-5.52l-4.43-4.43a3 3 0 1 1 1.41-1.41l4.43 4.43c.53-.35 1.12-.6 1.72-.72v9.78zM22.15 0h9.78a5 5 0 0 1-.72 3.8l4.44 4.43a3 3 0 1 1-1.42 1.42L29.8 5.2a5 5 0 0 1-5.52 0l-4.43 4.44a3 3 0 1 1-1.41-1.42l4.43-4.43a5 5 0 0 1-.72-3.8zm0 52c.13-.6.37-1.19.72-1.72l-4.43-4.43a3 3 0 1 1 1.41-1.41l4.43 4.43a5 5 0 0 1 5.52 0l4.43-4.43a3 3 0 1 1 1.42 1.41l-4.44 4.43c.36.53.6 1.12.72 1.72h-9.78zm9.75-24a5 5 0 0 1-3.9 3.9v6.27a3 3 0 1 1-2 0V31.9a5 5 0 0 1-3.9-3.9h-6.27a3 3 0 1 1 0-2h6.27a5 5 0 0 1 3.9-3.9v-6.27a3 3 0 1 1 2 0v6.27a5 5 0 0 1 3.9 3.9h6.27a3 3 0 1 1 0 2H31.9z'%3E%3C/path%3E%3C/svg%3E");  padding: 10px 0;
  margin: 0;
  border-bottom: 12px solid  $dark;
  text-align: center;
  font-size: 1em;

  @media (min-width: 1110px) {
    font-size: 1em
  }
}

.container {
  border-top: 12px solid $dark;
  position: relative;
  top: -156px;

  @media (min-width: 1110px) {
    top: 120px;
  }
}

.page-container {
  position: relative;
  flex: 1;
  top: -156px;
  border-top: 12px solid $dark;

  @media (min-width: 1110px) {
    border-top: none;
    top: -238px;
  }
}

.post-footer {
  position: relative;
  top: 150px;
}

section {
  margin: 0 auto;
  max-width: 1250px;
  padding: 0 20px;

  @media (min-width: 1110px) {
    width: 800px;
  }
}

.portfolio {
  @media (min-width: 1110px) {
    width: 100%;
  }
}

p a, li a.bookstore, pre a {
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  background: -webkit-gradient(linear,left top,right top,from(#e4eefc),to(#ccfffa));
  background: linear-gradient(to right,#e4eefc,#ccfffa);
  padding: 1px 5px;
  text-decoration: none;
  color: black;
  border-radius: 10px;
  border-bottom: 3px solid #e0f1fc;
}

p a:hover, li a.bookstore:hover, pre a:hover {
  background: -webkit-gradient(linear,left top,right top,from(#e4eefc),to(#ccfffa));
  background: linear-gradient(to right,#e6fce4,#f4ffcc);
  border-bottom: 3px solid #e5fce0;
}

.subtitle {
  display: block;
  font-size: 0.5em;
  letter-spacing: 2px;
  text-transform: uppercase;
}

footer {
  border-top: 20px solid $dark;
  padding: 30px;
  background-color: #232323;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='52' height='52' viewBox='0 0 52 52'%3E%3Cpath fill='%23407480' fill-opacity='0.11' d='M0 17.83V0h17.83a3 3 0 0 1-5.66 2H5.9A5 5 0 0 1 2 5.9v6.27a3 3 0 0 1-2 5.66zm0 18.34a3 3 0 0 1 2 5.66v6.27A5 5 0 0 1 5.9 52h6.27a3 3 0 0 1 5.66 0H0V36.17zM36.17 52a3 3 0 0 1 5.66 0h6.27a5 5 0 0 1 3.9-3.9v-6.27a3 3 0 0 1 0-5.66V52H36.17zM0 31.93v-9.78a5 5 0 0 1 3.8.72l4.43-4.43a3 3 0 1 1 1.42 1.41L5.2 24.28a5 5 0 0 1 0 5.52l4.44 4.43a3 3 0 1 1-1.42 1.42L3.8 31.2a5 5 0 0 1-3.8.72zm52-14.1a3 3 0 0 1 0-5.66V5.9A5 5 0 0 1 48.1 2h-6.27a3 3 0 0 1-5.66-2H52v17.83zm0 14.1a4.97 4.97 0 0 1-1.72-.72l-4.43 4.44a3 3 0 1 1-1.41-1.42l4.43-4.43a5 5 0 0 1 0-5.52l-4.43-4.43a3 3 0 1 1 1.41-1.41l4.43 4.43c.53-.35 1.12-.6 1.72-.72v9.78zM22.15 0h9.78a5 5 0 0 1-.72 3.8l4.44 4.43a3 3 0 1 1-1.42 1.42L29.8 5.2a5 5 0 0 1-5.52 0l-4.43 4.44a3 3 0 1 1-1.41-1.42l4.43-4.43a5 5 0 0 1-.72-3.8zm0 52c.13-.6.37-1.19.72-1.72l-4.43-4.43a3 3 0 1 1 1.41-1.41l4.43 4.43a5 5 0 0 1 5.52 0l4.43-4.43a3 3 0 1 1 1.42 1.41l-4.44 4.43c.36.53.6 1.12.72 1.72h-9.78zm9.75-24a5 5 0 0 1-3.9 3.9v6.27a3 3 0 1 1-2 0V31.9a5 5 0 0 1-3.9-3.9h-6.27a3 3 0 1 1 0-2h6.27a5 5 0 0 1 3.9-3.9v-6.27a3 3 0 1 1 2 0v6.27a5 5 0 0 1 3.9 3.9h6.27a3 3 0 1 1 0 2H31.9z'%3E%3C/path%3E%3C/svg%3E");
  position: relative;
  top: 150px;
}

footer .wrapper {
  max-width: 1120px;
  display: flex;
  margin: 0 auto;
}

footer ul {
  display: flex;
  padding: 0 70px;
  flex-direction: column;
}

footer li {
  flex: 1;
  list-style: none;
  font-size: 0.7em;

  @media (min-width: 1110px) {
    font-size: 0.9em;
  }
}

footer li a {
  text-decoration: none;
  color: white;
}

.home_img {
  float: right;
  width: 100%;
  margin: 20px 0;

  @media (min-width: 1110px) {
    width: 240px;
    border: 5px solid $dark;
  }

  &:hover {
    filter: brightness(1.2);
  }
}

.sshot {
  float: left;
  margin: 20px 0;
  width: 100%;

  @media (min-width: 1110px) {
    width: 440px;
    border: 5px solid $dark;
    margin: 20px;
  }

  &:hover {
    filter: brightness(1.1);
  }
}

.image-grid {
  border-radius: 0px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  flex-direction: row;
  padding: 40px 0 0;
}

li.image {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  padding: 0 10px;

  @media (min-width: 1110px) {
    padding: 10px;
  }
}

li.image a:hover {
  border-bottom: none;
  filter: brightness(1.2);
}

.cover {
  height: 330px;
  border: 5px solid $dark;
  width: auto;
}

.metadata {
  flex: 1;
  font-size: 22px;
  width: 200px;
  line-height: 28px;
  margin: 10px 0 10px 0px
}

.bookstores {
  display: flex;
  flex-flow: row wrap;
  list-style-type: none;
  padding-inline-start: 0
}

.bookstore {
  flex: 1 auto;
  text-align: center;
  margin: 20px 10px 10px 0;
  line-height: 50px;
}

.portfolio-container {
  display: flex;
  flex-direction: column;

  @media (min-width: 1110px) {
    flex-direction: row;
    max-width: 900px !important;
  }
}

.content {
  flex: 2;

  @media (min-width: 1110px) {
    margin-left: 30px;
  }
}

.sidebar {
  flex: 1;
}

.large {
  width: 100%;

  &:hover {
    filter: brightness(1.3);
  }
}

h1.book-page {
  background: none;
  text-align: left;
  letter-spacing: normal;

  @media (min-width: 1110px) {
    font-size: 65px;
  }
}

pre {
  font-family: Roboto, sans-serif;
  white-space: pre-line;
  font-size: 20px;
  line-height: 30px;

  a[href$=jpg], a[href$=jpeg], a[href$=jpe], a[href$=png], a[href$=gif] {
    background: white;
    border-bottom: 0;

    &:hover {
      background: white;
      border-bottom: 0;
    }
  }

  .pic a {
    background: white;
    border-bottom: 0;

    &:hover {
      background: white;
      border-bottom: 0;
    }
  }

  & img {
    margin-right: 20px;
    float: left;
    padding: 70px 50px 30px 0;
    margin: 0 0 10px;
  }

  & ul {
    padding: 0;
  }

  & li {
    list-style-type: disc;
  }

  & p {
    margin-block-start: 0;
    margin-block-end: 0;
    font-family: Roboto, sans-serif;
    font-size: 20px;
    line-height: 30px;
  }

  & img {
    text-align: center;
    width: auto;
    height: auto;
    display: block;
    position: relative;
    margin-right: 20px;
  }

  & img:before {
    content: " ";
    display: block;
    position: absolute;
    top: -10px;
    left: 0;
    height: calc(100% + 10px);
    width: 100%;
    background-color: rgb(230, 230, 230);
    border: 2px dotted rgb(200, 200, 200);
    border-radius: 5px;
  }

  & img:after {
    content: "\f127" " Broken Image of " attr(alt);
    display: block;
    font-size: 16px;
    font-style: normal;
    font-family: Roboto, sans-serif;
    color: rgb(100, 100, 100);
    position: absolute;
    top: 5px;
    left: 0;
    width: 100%;
    text-align: center;
  }
}

.pic {
  display: flex;
  flex-direction: column;
  float: left;

  p {
    flex: 1;
    font-size: 16px;
    color: grey;
  }
}

h2 {
  margin-block-end: -10px;
}

h3 {
  font-size: 1em;
}

address {
  font-size: 0.7em;
}

hr {
  border-bottom: 12px solid $dark;
}

.post-nav {
  display: flex;
}

.post-nav div {
  flex: 1 1 0;
  font-size: 0.7em;

  @media (min-width: 1110px) {
    font-size: 1em;
  }
}

.post-nav-next {
  text-align: right;
}

.blog-meta {
  color: #9b97a5;
  line-height: 30px;
  font-size: 18px;
}

img:-moz-broken{
  opacity: 0;
}

li.archive {
  font-size: 0.7em
}
